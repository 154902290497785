<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center">
              <p><font size="+2">Columbia University Image Library<br>
                (COIL-100) </font></p>
              </div></td>
          </tr>
          <tr>
            <td><div align="center"><img src="https://cave.cs.columbia.edu/old/software/softlib/gif/100objects.jpg" width="600" height="400"></div></td>
          </tr>
          <tr>
            <td><span class="style2">Access Instructions</span>
              <p> To download the 100 object database click at: [<a href="http://cave.cs.columbia.edu/old/databases/SLAM_coil-20_coil-100/coil-100/coil-100.zip">processed</a>]. 
              For formal documentation look at the corresponding compressed technical report, [<a href="http://cave.cs.columbia.edu/old/databases/SLAM_coil-20_coil-100/coil-100/coil-100.tar.gz">gzipped</a>]. 
              The unprocessed database has not been retained.
              </p>
              <dl>
                <dl>
                  <dt>"Columbia Object Image Library (COIL-100),"
                  </dt><dd>S. A. Nene, S. K. Nayar and H. Murase,
                  </dd><dd>Technical Report CUCS-006-96, February 1996.
                  </dd><dd>[<a href="http://cave.cs.columbia.edu/old/databases/papers/nene/nene-nayar-murase-coil-100.ps.gz">gzipped</a>][<a href="http://cave.cs.columbia.edu/old/databases/papers/nene/nene-nayar-murase-coil-100.ps">uncompressed</a>]<br>
                    <br>
                    <br>              
              </dd></dl>
              </dl>
              <hr size="5">
              <a href="mailto:webcave@lists.cs.columbia.edu"><em><br>
            Contact person for COIL-100</em></a></td></tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>